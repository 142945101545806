import { TFunction } from '@sortlist-frontend/translation/ssr';
import { getStorage, HttpException, isString } from '@sortlist-frontend/utils';
import { GraphQLError } from 'graphql';

import { trackBriefingError } from '_components/Briefing/trackers';

import { FINISH_BRIEFING } from './versions/NormalBriefing/BriefingDialog';

export const isUserError = (e: HttpException) => {
  const { details } = e || {};
  const errorDetails = details?.email as Record<string, unknown>[];
  const emailError = errorDetails?.[0]?.error;

  return isString(emailError) && ['invalid', 'disposable'].includes(emailError);
};

export const getBackendErrorMessage = (backendError: any, t: TFunction, projectUuid?: string): string => {
  const errors: GraphQLError[] = backendError?.previousError?.response?.data;
  const messages = errors?.map((e) => getUserErrorMessage(e as GraphQLError, t));
  const message = messages?.[0] || t('common:errors.errorWhilePostingForm');
  trackBriefingError({ error: message, projectUuid });
  return message;
};

export const redirectToLobby = (redirectUrl: string, page?: string) => {
  if (page === 'briefing-embed') {
    window.parent.postMessage({ results: redirectUrl }, '*');
    window.parent.postMessage({ action: FINISH_BRIEFING, data: { results: redirectUrl } }, '*');
  } else {
    window.location.href = redirectUrl;
  }
};

export const getStoreData = () => {
  const data = getStorage('directBriefingDataKey');
  const longtailData = data ? JSON.parse(data) : undefined;
  const expertise = longtailData?.expertise?.id;
  const placeId = longtailData?.location?.placeId || longtailData?.location?.place_id;
  const address = longtailData?.location?.address || longtailData?.location?.name;
  return {
    ...(expertise && { expertise }),
    ...(placeId && { placeId }),
    ...(address && { address }),
  };
};

export const scrollToFirstError = (errors: Record<string, any>) => {
  const errorKeys = Object.keys(errors);
  const firstError = errorKeys[0];
  const firstErrorElement = document.getElementById(`${firstError}-question-answer`);
  firstErrorElement?.scrollIntoView({ behavior: 'smooth' });
};

//To improve if there is other type of backend error
export const getUserErrorMessage = (error: GraphQLError, t: TFunction) => {
  const errorDetails = error.extensions?.details;
  if (errorDetails?.email) {
    const errorType = errorDetails.email?.[0]?.error;
    if (errorType === 'disposable') return t('briefing:errors.disposableEmail');
    return t('briefing:errors.notEmail');
  }
  return t('briefing:errors.somethingWrong');
};

const PERSONAL_EMAIL_DOMAINS = [
  'aol.com',
  'bluewin.ch',
  'email.com',
  'email.de',
  'free.fr',
  'freenet.de',
  'gmail.com',
  'googlemail.com',
  'gmail.es',
  'gmx.at',
  'gmx.com',
  'gmx.de',
  'gmx.fr',
  'gmx.net',
  'hotmail.com',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.co.uk',
  'icloud.com',
  'cloud.es',
  'me.com',
  'mac.com',
  'laposte.net',
  'libero.it',
  'live.be',
  'live.co.uk',
  'live.com',
  'live.de',
  'live.fr',
  'live.it',
  'live.nl',
  'mail.com',
  'mail.ru',
  'mail.de',
  'mailo.com',
  'msn.com',
  'orange.fr',
  'outlook.com',
  'outlook.fr',
  'proton.me',
  'protonmail.com',
  'qq.com',
  'rediffmail.com',
  'sfr.fr',
  'skynet.be',
  't-online.de',
  'telenet.be',
  'wanadoo.fr',
  'web.de',
  'yahoo.com',
  'yahoo.fr',
  'yahoo.co.uk',
  'yahoo.co.br',
  'yahoo.co.in',
  'yahoo.ro',
  'yandex.ru',
  'ymail.com',
];

export const isPersonalEmail = (email?: string) => {
  const domain = email?.split('@')[1];
  if (domain == null) return false;
  return PERSONAL_EMAIL_DOMAINS.includes(domain);
};
