import { Button, Color, Size, Variant } from '@sortlist-frontend/design-system';
import dynamic from 'next/dynamic';
import { Fragment, ReactElement, useState } from 'react';

import { getStoreData } from '_components/Briefing/utils';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useGetAgencyProfile } from '_core/repos/agency-profile.repo';

import type { DirectBriefingProps } from './types';

const DirectBriefing = dynamic<DirectBriefingProps>(
  () => import('_components/Briefing/versions/DirectBriefing').then((mod) => mod.DirectBriefing),
  { ssr: false },
);

type Props = {
  page?: string;
  cta?: string;
  agencySlug: string;
  truncate?: boolean;
  size?: Size;
  buttonStyle?: Color;
  buttonVariant?: Variant;
  className?: string;
  label?: string | ReactElement;
  fab?: boolean;
  icon?: ReactElement;
};

export const DirectBriefingButton: React.FC<Props> = (props) => {
  const {
    page,
    cta,
    agencySlug,
    size,
    buttonStyle,
    buttonVariant,
    className,
    label,
    fab = false,
    icon,
    ...restProps
  } = props;

  const [open, setOpen] = useState(false);
  const labelProps = fab ? { fab, icon } : { label };

  const { domainInfo, briefingOptions, locale } = usePublicAppContext();
  const { data: agency } = useGetAgencyProfile(agencySlug, locale as string);

  // Try to prefill with data of the longtail (set when user click the agency card)
  const storeData = getStoreData();
  const longtailExpertise = storeData?.expertise;
  const longtailPlaceId = storeData?.placeId;
  const longtailAddress = storeData?.address;

  // Try to prefill with data from the page itself (agency main office and main service)
  const { expertise, skills, placeId, address, iso31661 } = briefingOptions || {};

  // Try to prefill with domain info as last fallback
  const currency = domainInfo?.getCurrency();
  const defaultIso31661 = domainInfo?.getIso31661();
  const { placeId: defaultPlaceId, address: defaultAddress } = domainInfo?.getLocation(locale) ?? {};

  // Next doesn't handle regional locales yet.. The only one we handle at the moment is nl-BE.
  // To make sure the project are posted correctly and handled by the right person, we need this trick.
  const regionalizedLocale = domainInfo?.getIso31661() === 'BE' && locale === 'nl' ? 'nl-BE' : locale;

  return (
    <Fragment>
      <Button
        size={size || 'sm'}
        buttonStyle={buttonStyle || 'primary'}
        buttonVariant={buttonVariant || 'raised'}
        animation={'ripple'}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        className={className}
        data-testid="direct-briefing-button"
        {...labelProps}
        {...restProps}
      />

      {open && (
        <DirectBriefing
          page={page}
          cta={cta}
          address={longtailAddress || address || defaultAddress}
          placeId={longtailPlaceId || placeId || defaultPlaceId}
          expertise={longtailExpertise || expertise}
          locale={regionalizedLocale || 'en'}
          currency={currency as string}
          iso31661={iso31661 || defaultIso31661 || undefined}
          skills={skills}
          agencySlug={agencySlug}
          agencyName={agency?.name as string}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </Fragment>
  );
};
